import { ReactElement } from 'react';

import PhotoGalleryBlock from 'components/UI/PhotoGalleryBlock';
import RichText from '../UI/Richtext';
import { MAX_SCREEN_WIDTH } from 'lib/consts';
import { Asset } from 'contentful';
import type { Document } from '@contentful/rich-text-types';

interface PhotoGallertProps {
  heading: string;
  copy?: Document | undefined;
  imagesPerRow: number;
  gallery: PhotoBlockProps[];
}

export interface PhotoBlockProps {
  image: Asset;
  rowOneText?: string | undefined;
  rowTwoText?: string | undefined;
  rowThreeText?: string | undefined;
  link?: string | undefined;
  openInNewTab?: boolean | undefined;
}

export default function PhotoGallery({
  heading,
  gallery,
  copy,
  imagesPerRow,
}: PhotoGallertProps): ReactElement {
  const maxImageWidth = Math.round(MAX_SCREEN_WIDTH / imagesPerRow);
  return (
    <div className="px-4 lg:px-16 xl:px-32 py-12">
      <h2 className="font-heading text-2xl lg:text-4xl ml-2">{heading}</h2>
      {copy && <RichText document={copy} classNames="ml-2 my-4" />}
      <div className="flex flex-wrap mt-4">
        {gallery.map((image, i) => (
          <PhotoGalleryBlock key={i} {...image} width={imagesPerRow} query={`w=${maxImageWidth}`} />
        ))}
      </div>
    </div>
  );
}
